import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started-oli-checkout"
    }}>{`Getting Started Oli-Checkout`}</h1>
    <p><em parentName="p">{`Integrations API from multiples platforms`}</em></p>
    <hr></hr>
    <h2 {...{
      "id": "configuration"
    }}>{`Configuration`}</h2>
    <pre><code parentName="pre" {...{}}>{`- The configuration need the access token, the access token can be generation on the system menu of your ecommerce, system -> integration.
- If your system don´t have any integration, click in the button "Add New Integration", after created you have the access token.
`}</code></pre>
    <h2 {...{
      "id": "requirements"
    }}>{`Requirements`}</h2>
    <pre><code parentName="pre" {...{}}>{`- URL
- Access Token
`}</code></pre>
    <h2 {...{
      "id": "permissions-keys"
    }}>{`Permissions keys`}</h2>
    <pre><code parentName="pre" {...{}}>{`- Read
- Write
`}</code></pre>
    <h2 {...{
      "id": "routes-integrations"
    }}>{`Routes Integrations`}</h2>
    <pre><code parentName="pre" {...{}}>{`- Router GET - rest/V1/stockItems/
- Router GET - rest/V1/products?searchCriteria
- Router POST - rest/V1/carts
- Router POST - rest/V1/carts/mine/items
`}</code></pre>
    <hr></hr>
    <h2 {...{
      "id": "cart-checkout"
    }}>{`Cart Checkout`}</h2>
    <p><em parentName="p">{`Simple generate url to redirect users from shopping cart.`}</em></p>
    <h3 {...{
      "id": "post-router"
    }}>{`POST Router:`}</h3>
    <p><em parentName="p">{`https://Domain/checkout/cart`}</em></p>
    <h4 {...{
      "id": "request"
    }}>{`Request:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "items": {
    "id": "string",
    "quantity": 0,
  },
  "clientProfileData": {
    "liveId": "fake-ab1c-4b59-a65e-38283984ed46"
  }
}
`}</code></pre>
    <h4 {...{
      "id": "response"
    }}>{`Response:`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "message": "string",
  "status": "string",
  "data": {
    "url": "https://domainClient.com.br/checkout/
  }
}
`}</code></pre>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      